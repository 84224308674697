import * as ACTIONS from '../../reduxLoop/actions/homeActions';

require('./wsHome.scss');

class wsHome {
  constructor($scope, $element, $ngRedux, ngMeta) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;
    this.$ngRedux = $ngRedux;
    this.ngMeta = ngMeta;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect(state => ({
      ...state.vm.homePage,
    }))(this);

    this.$ngRedux.dispatch(ACTIONS.loadHome(this.sSource));

    this.$scope.$emit('showHeader', true);
    this.$scope.$emit('showCompactHeader', false);
  }

  $onDestroy() {
    this.$scope.$emit('showHeader', false);
    this.unsubscribe();
  }

  itemBackgroundImage(item, field) {
    return item && item[field] ? { background: `url('${item[field]}')` } : undefined;
  }

  itemBackgroundCoverImage(item) {
    return this.itemBackgroundImage(item, 'coverImage');
  }

  itemBackgroundThumbnailImage(item) {
    return this.itemBackgroundImage(item, 'thumbnailImage');
  }
}

export default {
  template: require('./wsHome.html'),
  controllerAs: 'ctrl',
  controller: wsHome,
  bindings: {
    sSource: '<',
  },
};
