// Compile directive: Allow angular to change HTML on the fly

const sanitize = require('sanitize-html');

export default ['$compile', 'termHelper', 'routerService', ($compile, termHelper, routerService) => {
  function sanitizeHtml(string, noBlankTarget) {
    string = sanitize(string, {
      allowedTags: ['b', 'em', 'strong', 'a', 'li', 'ul', 'ol', 'p', 'br', 'table', 'tr', 'td', 'tbody', 'ws-term'],
      allowedAttributes: {
        a: ['href', 'target', 'class', 'rel'],
        li: [],
        ul: ['class'],
        table: ['class'],
        'ws-term': ['ws-href'],
      },
      allowedClasses: {
        ul: ['list-basic-disc'],
        table: ['table', 'table-striped'],
        a: ['theme-content-link'],
      },
      selfClosing: ['br'],
      nonTextTags: ['style', 'script', 'textarea', 'noscript'],
      transformTags: {
        ul(tagName, attribs) {
          return {
            tagName: 'ul',
            attribs: {
              class: 'list-basic-disc',
            },
          };
        },
        /* br: function (tagName, attribs) {
          return {
            tagName: 'p',
          };
        }, */
        table(tagName, attribs) {
          return {
            tagName: 'table',
            attribs: {
              class: 'table table-striped',
            },
          };
        },
        a(tagName, attribs) {
          if (attribs.href) {
            if (attribs.rel === 'term') {
              return {
                tagName: 'a',
                attribs: {
                  href: attribs.href,
                  rel: attribs.rel,
                },
              };
            } if (attribs.rel === 'webpage2-link') {
              const webpageInfo = routerService.getWebpageInfoFromHref(attribs.href);
              return {
                tagName: 'a',
                attribs: {
                  href: webpageInfo ? webpageInfo.path : attribs.href,
                  class: 'theme-content-link',
                },
              };
            }
            return {
              tagName: 'a',
              attribs: {
                target: noBlankTarget || attribs.href.startsWith('/') ? '_self' : '_blank',
                class: 'theme-content-link',
                href: routerService.getValidUrl(attribs.href),
              },
            };
          }
          return {};
        },
      },
    });
    return string;
  }

  const filterHtml = function (string) {
    string = sanitize(string, {
      allowedTags: ['br', 'ul', 'ol', 'li', 'b'],
    });
    return string;
  };


  return {
    restrict: 'A',
    link(scope, element, attrs) {
      const useTerm = scope.$eval(attrs.compileTerm);
      const noHtml = scope.$eval(attrs.compileNoHtml);
      const noBlankTarget = scope.$eval(attrs.compileNoBlankTarget);
      let prevValue;
      scope.$watch(
        scope => scope.$eval(attrs.compile),
        (value) => {
          if (prevValue !== value) {
            if (typeof value !== 'undefined' && value !== null) {
              if (noHtml) {
                value = filterHtml(value);
              } else {
                value = sanitizeHtml(value, noBlankTarget);
              }
              if (useTerm) {
                if (useTerm === 'highlight') {
                  value = termHelper.highlightTerm(value);
                } else {
                  value = termHelper.replaceTerm(value);
                }
              } else {
                value = termHelper.removeTerm(value);
              }
              element.html(value);
              $compile(element.contents())(scope);
            }
          }
        },
      );
    },
  };
}];
