require('./ws404.scss');

class ws404 {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
  }

  $onInit() {
    this.$scope.$emit('showCompactHeader', true);
  }

  $onDestroy() {
    this.$scope.$emit('showCompactHeader', false);
  }
}

module.exports = {
  template: require('./ws404.html'),
  controllerAs: 'ctrl',
  controller: ws404,
};
