/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { getNow } from '@kathondvla/sri-client/date-utils';
import { cachedContentApi, searchClient } from '../api/apiConfig';
import { getContentHash } from './contentCommands';
import { NEWS_IN_HOME_TYPES, WWW_SEARCH_TYPE } from '../static/constants';

export const loadHomeReferences = async (rootHref) => {
  const hash = await getContentHash(rootHref);

  const items = await cachedContentApi.getAll('/content', {
    root: rootHref,
  }, { headers: { 'VSKO-Resource-Hash': hash.hash } });

  const referencesHrefs = items.filter(item => item.type === 'REFERENCE').map((item) => {
    const referencesRelation = item.$$relationsFrom.find(relation => relation.$$expanded.relationtype === 'REFERENCES');
    return referencesRelation.$$expanded.to.href;
  });
  // we should be able to use cached api for this request and batch doesn't support it (use hrefs=... )
  // references shouldn't be much in home page and so will not exceed the max number
  const references = await cachedContentApi.getAll('/content', {
    hrefs: referencesHrefs.join(','),
  }, { headers: { 'VSKO-Resource-Hash': hash.hash } });

  return {
    items,
    references,
  };
};

export const loadHomeNews = async () => {
  const news = await searchClient.getRaw('/search', {
    types: WWW_SEARCH_TYPE,
    wwwWebPageType: NEWS_IN_HOME_TYPES.join(','),
    issuedBeforeOrEqual: getNow(),
  }, {});

  return news.results;
};
