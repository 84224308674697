/* eslint-disable max-len */
import { Cmd, loop } from 'redux-loop';
import { stateTransitionTo } from 'redux-ui-router';
import * as ACTION_TYPES from '../constants/actionTypes';
import { TRANSITIONING_STATE } from '../static/constants';
import { getStateToGo, externalRedirectUrl } from '../helpers/dynamicTransitionHelpers';
import * as TRANSITION_ACTIONS from '../actions/transitionActions';
import * as TRANSITION_COMMANDS from '../commands/transitionsCommands';
import * as CONFIGURATIONS_COMMANDS from '../commands/configurationsCommands';

const initialState = {
};

export default (state = initialState, action, rootState) => {
  switch (action.type) {
    case ACTION_TYPES.STATE_CHANGE_SUCCESS: {
      let loopCmd = Cmd.none;
      let newStateToGo;

      if (rootState.router.currentState.name === TRANSITIONING_STATE && rootState.configurations.webpages.length) {
        // try to find the current path in webconfigs and if exists do a transition to the correspodning state that should handle it
        const targetedPath = rootState.router.currentParams.pathToResolve;
        const webconfiguration = rootState.configurations.webpages.find(wc => wc.path === targetedPath || wc.source.href === targetedPath);

        // console.log('Custom STATE_CHANGE_SUCCESS routerState:', rootState.router);

        newStateToGo = getStateToGo(webconfiguration);

        if (newStateToGo) {
          loopCmd = Cmd.action(stateTransitionTo(newStateToGo.name, newStateToGo.params, { location: false }));
        } else {
          loopCmd = Cmd.run(CONFIGURATIONS_COMMANDS.getWebconfigurationWithOldUrl, {
            args: [targetedPath],
            successActionCreator: TRANSITION_ACTIONS.handleExternalRedirectOrNotFound,
            failActionCreator: error => console.log('ERROR loading content tree:', error),
          });
        }
      }

      return loop({
        ...state,
        redirectedToState: newStateToGo || state.redirectedToState,
      }, loopCmd);
    }

    case ACTION_TYPES.HANDLE_EXTERNAL_REDIRECT_OR_NOT_FOUND: {
      const redirect = action.payload && action.payload.length
        ? action.payload[0] : false;

      return loop({
        ...state,
      }, redirect
        ? Cmd.run(TRANSITION_COMMANDS.externalRedirect, {
          args: [externalRedirectUrl(redirect)],
        })
        : Cmd.action(stateTransitionTo('404')));
    }

    default:
      return state;
  }
};
