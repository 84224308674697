import settings from '../../config/settings';

export default (Analytics, $transitions, $location, online) => {
  if (!settings.googlAnalytics.enabled) return;

  Analytics.offline(!online);

  if (online) {
    Analytics.registerScriptTags();
    Analytics.registerTrackers();
    Analytics.pageView();
    $transitions.onSuccess({}, () => Analytics.trackPage($location.url()));
  }
};
