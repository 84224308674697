/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

import { buildTree } from '../../module/contentTree';
import { getStaticHtml } from './staticContentHelpers';
import { getStructuredDocumentRoot } from './themaHelpers';

export const getContentPageItems = (source, state) => (state.content.pages[source]
  ? state.content.pages[source] : undefined);

// get static content for a menu (could be header or footer)
export const getMenuContent = (state, key) => {
  const items = getContentPageItems(`/content/${key}`, state);
  const tree = items && items.length ? buildTree(getStructuredDocumentRoot(items).key, items, state.configurations.webpages) : undefined;
  if (!tree) {
    return {
      loading: true,
    };
  }

  const menuHtml = getStaticHtml(tree);

  return {
    html: menuHtml,
    loading: menuHtml === undefined,
  };
};
