import { contentApi, searchClient } from '../api/apiConfig';
import { buildRequestParams } from '../helpers/newsHelpers';

export const getNewsHash = (href, isPreview) => contentApi.get(`${href}/hash${isPreview ? `?${new Date().toISOString()}` : ''}`, {});

export const loadNews = async (filter) => {
  const items = await searchClient.getRaw('/search', buildRequestParams(filter), {});

  return items.results;
};
