import { websitesApi } from '../api/apiConfig';
import settings from '../../config/settings';

// eslint-disable-next-line import/prefer-default-export
export const loadWebconfigurations = () => websitesApi.getAll('/web/pages', {
  expand: 'results.website,results.template',
  website: settings.website,
});

export const getWebconfigurationWithOldUrl = oldPath => websitesApi.getAll('/web/pages', {
  oldUrlsContains: `${window.location.protocol}//${window.location.hostname}${oldPath}`,
  expand: 'results.website',
});
