class ws500 {
  constructor() {
    'ngInject';
  }

  $onInit() {

  }
}

module.exports = {
  template: require('./ws500.html'),
  controllerAs: 'ctrl',
  controller: ws500,
  bindings: {},
};
