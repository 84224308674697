/* eslint-disable max-len */
import * as constants from '../static/constants';

export const getStateToGo = (webconfiguration) => {
  if (!webconfiguration) {
    return undefined;
  }
  const templateCode = webconfiguration.template.$$expanded.code;
  let stateToGo = {
    path: webconfiguration.path,
    params: {
      source: webconfiguration.source.href,
    },
  };

  // SET TYPE MATCH TO STATE
  switch (templateCode) {
    case constants.WWW_HOME_PAGE:
      stateToGo.name = 'home';
      break;
    case constants.WWW_HARD_CODED:
      stateToGo.name = 'static';
      break;
    case constants.WWW_CURRENT_THEME_PAGE:
      stateToGo.name = 'themeDetail';
      break;
    case constants.WWW_INFORMATION_PAGE:
      stateToGo.name = 'information';
      break;
    case constants.WWW_NEWS_DETAIL:
      stateToGo.name = 'newsDetail';
      break;
    default:
      stateToGo = constants.DEFAULT_TEMPLATE_STATE;
      break;
  }

  return stateToGo;
};

export const externalRedirectUrl = webconfiguration => (`https://${webconfiguration.website.$$expanded.domain}${webconfiguration.path}`);
