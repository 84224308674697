/* eslint-disable func-names */
import { createAppStore } from '../reduxLoop/store';
import { initApiConfig } from '../reduxLoop/api/apiConfig';
import settings from '../config/settings';

// BOOTSTRAP (kind of ugly, because it expects jQuery)
window.$ = require('jquery');

window.jQuery = window.$;

const app = require('./app.js').default;

require('lodash');
require('../module/directives');
require('../components/index');
require('../screens');

// also add the window.settings as a constant to our angular module
app.constant('settings', settings);
app.constant('utils', require('../utils/utils').default);
app.constant('constants', require('../components/constants.js').default);

app.config(($ngReduxProvider, $stateProvider) => {
  $ngReduxProvider.provideStore(createAppStore($stateProvider));
});

app.config(require('./config.js')).config(['$compileProvider', function ($compileProvider) {
  $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|file|javascript):/);
}]);

app.value('$routerRootComponent', 'appRoot');

// Allow inclusion of all urls
app.config(['$sceDelegateProvider', function ($sceDelegateProvider) {
  // TODO: only kathonvla
  $sceDelegateProvider.resourceUrlWhitelist(['**']);
}]);

app.config(['$sessionStorageProvider', function ($sessionStorageProvider) {
  $sessionStorageProvider.setKeyPrefix('');
}]);

app.run(['$q', function ($q) {
  // Use Angular's Q object as Promise. This is needed to make async/await work
  // properly with the UI. See http://stackoverflow.com/a/41825004/536
  window.Promise = $q;
}]);

app.filter('startFrom', () => function (input, start) {
  if (input) {
    start = +start; // parse to int
    return input.slice(start);
  }
  return [];
});

// Configuation that has to do with router
app.run(['$transitions', '$state', '$window', function ($transitions, $state, $window) {
  // Show 404 when state not found
  $transitions.onError({}, ($transition) => {
    if ($transition.error().type !== 5) {
      console.warn($transition.error());
      // $state.go('404');
    }
  });

  // Scroll to top on page change
  // Clear notifications on page change
  $transitions.onFinish({}, () => {
    $window.scrollTo(0, 0);
  });
}]);

app.run(($rootScope, routerService) => {
  $rootScope.$on('$locationChangeSuccess', (_event, newUrl) => {
    routerService.setPreviousUrl(newUrl);
  });
});

app.run(['ngMeta', function (ngMeta) {
  ngMeta.init();
}]);

app.run([function () {
  // Fixed header
  $(window).scroll(function () {
    if ($(this).scrollTop() > 134) {
      $('#menu').addClass('fixed-header');
    } else {
      $('#menu').removeClass('fixed-header');
    }
  });
}]);

export default app;
