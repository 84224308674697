import { initSiteCookiesModal } from '../../reduxLoop/actions/cookiesActions';

class appRoot {
  constructor(settings, $scope, $location, $sessionStorage, $cookies, $uibModal, ngMeta,
    $window, $transitions, Analytics, $ngRedux) {
    'ngInject';

    this.settings = settings;
    this.$scope = $scope;
    this.$location = $location;
    this.$sessionStorage = $sessionStorage;
    this.$cookies = $cookies;
    this.$uibModal = $uibModal;
    this.$transitions = $transitions;
    this.Analytics = Analytics;
    this.$ngRedux = $ngRedux;

    this.$window = $window;

    this.title = null;
    this.preview = false;
    this.ngMeta = ngMeta;
  }

  closeOverlayMenu() {
    $('#menu-mobile').removeClass('show');
    $('#overlay-menu').removeClass('show');
    $('.c-hamburger--htx').addClass('collapsed');
  }

  async $onInit() {
    this.$ngRedux.dispatch(initSiteCookiesModal(this.$uibModal, this.$cookies, this.$location, this.$transitions, this.Analytics));

    this.appLoaded = true;

    this.$scope.$on('showHeader', (event, show) => {
      this.showHeader = show;
      this.closeOverlayMenu();
    });

    this.$scope.$on('showCompactHeader', (event, show) => {
      this.showCompactHeader = show;
      this.closeOverlayMenu();
    });

    this.$scope.$on('leaveCookies', () => {
      // on leave /cookies screen => we need to open again the cookies constent modal if needed
      this.$ngRedux.dispatch(initSiteCookiesModal(this.$uibModal, this.$cookies, this.$location, this.$transitions, this.Analytics));
    });

    this.$scope.$on('dynamicMetaDataUpdated', (_event, page) => {
      // console.log('meta updated', page);
      this.ngMeta.setTitle(page.title);
      this.ngMeta.setTag('description', page.description);

      if (page.coverImage) {
        this.ngMeta.setTag('image', page.coverImage.href);
        this.ngMeta.setTag('imageWidth', '2000');
        this.ngMeta.setTag('imageHeight', '1600');
      }
      window.prerenderReady = true;
    });
  }
}

export default {
  template: require('./appRoot.html'),
  controllerAs: 'ctrl',
  controller: appRoot,
};
