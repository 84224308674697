import * as ACTIONS from '../../reduxLoop/actions/newsActions';
import settings from '../../config/settings';

require('./wsNewsOverview.scss');

class wsNewsOverview {
  constructor($scope, $element, ngMeta, $sce, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;
    this.ngMeta = ngMeta;
    this.$sce = $sce;
    this.$ngRedux = $ngRedux;

    this.proUrl = settings.apisAndUrls.pro;

    this.itemsPerPage = 3;
    this.page = 1;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect(state => ({
      allitems: state.vm.newsOverview.allitems,
      itemsToDisplay: state.vm.newsOverview.itemsToDisplay,
      filter: state.vm.newsOverview.filter,
      loading: state.vm.newsOverview.loading,
    }))(this);

    this.$ngRedux.dispatch(ACTIONS.loadNews(this.filter));

    this.$scope.$emit('showCompactHeader', true);
  }

  $onDestroy() {
    this.$scope.$emit('showCompactHeader', false);
  }

  onFilter(s, filter) {
    this.$ngRedux.dispatch(ACTIONS.updateNewsFilter(filter));
  }

  onClearFilter() {
    this.$ngRedux.dispatch(ACTIONS.clearNewsFilter());
  }

  onLoadMore() {
    this.$ngRedux.dispatch(ACTIONS.loadMoreNews());
  }
}

export default {
  template: require('./wsNewsOverview.html'),
  controllerAs: 'ctrl',
  controller: wsNewsOverview,
  bindings: {
    sSource: '<',
    sSourceParam: '<',
  },
};
