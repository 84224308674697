/* eslint-disable func-names */

import { cloneDeep } from 'lodash';

const service = (settings, $location, md5, $anchorScroll) => {
  'ngInject';

  const that = {};
  let previousUrl;

  that.setPreviousUrl = function (url) {
    previousUrl = url;
  };

  that.getPreviousUrl = function () {
    return previousUrl;
  };

  that.getPathFromUrl = function (urlStr) {
    const url = new URL(urlStr.startsWith('http') ? urlStr : `https://${urlStr}`);
    return url.pathname;
  };

  that.isProUrl = function (url) {
    return url.match(/^http(s):\/\/[test|acc]*pro\.katholiekonderwijs\.vlaanderen.*$/);
  };

  that.getValidUrl = function (href) {
    const newUrl = that.cachedNewUrls.get(href);

    if (newUrl) {
      return newUrl;
    }
    const webpageInfo = that.getWebpageInfoFromPath(href);

    if (webpageInfo) {
      return webpageInfo.path;
    }

    return href;
  };

  that.getNewUrlAsync = async function (path) {
    return new Promise((resolve, reject) => {
      that.initWebpages.then(() => {
        try {
          resolve(that.cachedNewUrls.get(path));
        } catch (e) {
          reject(e);
          throw e;
        }
      });
    });
  };

  that.hasNewVersionOutsideProAsync = async function (path) {
    return new Promise((resolve, reject) => {
      that.initWebpages.then(() => {
        try {
          resolve(that.cachedNewUrls.get(path) !== undefined);
        } catch (e) {
          reject(e);
          throw e;
        }
      });
    });
  };

  that.getWebpageInfoFromPath = function (path) {
    try {
      const cachedVersion = that.cachedPaths.get(path);
      if (cachedVersion) {
        return cachedVersion;
      }
      // console.warn('No webpage item found for ' + path);
      return null;
    } catch (e) {
      throw e;
    }
  };

  that.modifyWebpageTypeFromPath = function (path, type) {
    try {
      const cachedPathVersion = that.cachedPaths.get(path);
      if (cachedPathVersion) {
        const cachedHrefVersion = that.cachedSources.get(cachedPathVersion.source);
        cachedPathVersion.type = type;
        cachedHrefVersion.type = type;
      }
    } catch (e) {
      throw e;
    }
  };

  that.cloneWebpageInfoFromPath = function (path, newPath, href) {
    try {
      const cachedPathVersion = that.cachedPaths.get(path);
      if (cachedPathVersion) {
        const webPageInfo = cloneDeep(cachedPathVersion);
        webPageInfo.path = newPath;
        webPageInfo.source = href;
        that.cachedSources.set(href, webPageInfo);
        that.cachedPaths.set(newPath, webPageInfo);
      }
    } catch (e) {
      throw e;
    }
  };

  that.getWebpageInfoFromHref = function (href) {
    try {
      const cachedVersion = that.cachedSources.get(href);
      if (cachedVersion) {
        return cachedVersion;
      }
      // console.warn('No webpage item found for ' + href);
      return null;
    } catch (e) {
      throw e;
    }
  };

  that.getWebpageInfoAsync = async function (path) {
    return new Promise((resolve, reject) => {
      that.initWebpages.then(() => {
        try {
          let cachedVersion = that.cachedPaths.get(path);
          if (cachedVersion) {
            resolve(cachedVersion);
          } else {
            cachedVersion = that.cachedSources.get(path);
            if (cachedVersion) {
              resolve(cachedVersion);
            } else {
              console.warn(`No content item found for ${path}`);
              resolve(null);
            }
          }
        } catch (e) {
          reject(e);
          throw e;
        }
      });
    });
  };

  that.cachedPaths = new Map();
  that.cachedSources = new Map();
  that.cachedNewUrls = new Map();

  function getUrlFromOtherWebsite(domain, path) {
    const url = new URL(domain.startsWith('http') ? domain : `https://${domain}`);
    url.pathname = path;
    return url.href;
  }

  that.handleProPaths = function (webpages) {
    const proWebpages = webpages.filter(webpage => webpage.website.href === settings.website);
    proWebpages.forEach((webpage) => {
      that.cachedSources.set(webpage.source.href, that.webpageToWebpageInfo(webpage));

      if (webpage.oldLocations) {
        webpage.oldLocations.forEach((oldLocation) => {
          that.cachedPaths.set(oldLocation.path,that.webpageToWebpageInfo(webpage));
        });
      }
      that.cachedPaths.set(webpage.path, that.webpageToWebpageInfo(webpage));
    });
  };

  that.handleNotProUrls = function (webpages) {
    const othersWebpages = webpages.filter(
      webpage => webpage.website.href !== settings.website,
    );

    othersWebpages.forEach((webpage) => {
      if(webpage.oldLocation) {
        webpage.oldUrls.forEach((oldLoc) => {
          if (that.isProUrl(oldLoc.$$oldUrl)) {
            const newUrl = getUrlFromOtherWebsite(
                webpage.website.$$expanded.domain, webpage.path
            );
            that.cachedNewUrls.set(
                oldLoc.path,
                newUrl
            );

            const proOldPage = that.cachedPaths.get(oldLoc.path);
            if (proOldPage) {
              proOldPage.oldUrls.forEach((oldProUrl) => {
                const proOldPath = that.getPathFromUrl(oldProUrl);
                that.cachedNewUrls.set(
                    proOldPath,
                    newUrl
                );
              });
            }
          }
        });
      }
    });
  };

  that.switch = async function (href, notRedirectNotFound) {
    that.initWebpages.then(() => {
      try {
        const newUrl = that.cachedNewUrls.get(href);
        const cachedVersion = that.cachedSources.get(href);
        const params = $location.$$search;

        if (newUrl) {
          // window.location.href = newUrl;
          $location.path(newUrl).search(params).replace();
        } else if (cachedVersion) {
          $location.path(cachedVersion.path).search(params).replace();
        } else {
          console.warn(`No content item found for ${href}`);
          if (!notRedirectNotFound) {
            $location.path(href);
          }
        }
      } catch (e) {
        console.error(e);
      }
    });
  };

  that.webpageToWebpageInfo = function (websiteItem) {
    return {
      key: websiteItem.key,
      path: websiteItem.path,
      source: websiteItem.source.href,
      type: websiteItem.type,
      facets: websiteItem.options ? websiteItem.options.facets : [],
      multipleAlternativeMenus: !!(websiteItem.options
          && websiteItem.options.multipleAlternativeMenus),
      oldUrls: websiteItem.oldUrls,
    };
  };

  that.generateHash = function (key) {
    return `_${md5.createHash(key).slice(0, 8)}`;
  };

  that.scrollTo = function (anchor) {
    $anchorScroll(anchor);
    $location.search('scrollTo', null).replace();
  };

  return that;
};

angular.module('services').factory('routerService', service);
