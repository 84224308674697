import * as ACTION_TYPES from '../constants/actionTypes';

export const initSiteCookiesModal = ($uibModal, $cookies, $location, $transitions, Analytics) => ({
  type: ACTION_TYPES.INIT_SITE_COOKIES,
  payload: {
    $uibModal,
    $cookies,
    $location,
    $transitions,
    Analytics,
  },
});

export const showCookiesModal = ($uibModal, $cookies) => ({
  type: ACTION_TYPES.SHOW_COOKIES_MODAL,
  payload: {
    $uibModal,
    $cookies,
  },
});

export const setCookiesModal = modal => ({
  type: ACTION_TYPES.SET_COOKIES_MODAL,
  payload: modal,
});

export const closeCookiesModal = () => ({
  type: ACTION_TYPES.CLOSE_COOKIES_MODAL,
});

export const setCookies = (selectedCookies, $cookies) => ({
  type: ACTION_TYPES.SET_COOKIES,
  payload: {
    selectedCookies,
    $cookies,
  },
});

export const successfullySetCookies = cookiesData => ({
  type: ACTION_TYPES.SUCCESSFULLY_SET_COOKIES,
  payload: cookiesData,
});

export const loadedCookies = cookiesData => ({
  type: ACTION_TYPES.LOADED_COOKIES,
  payload: cookiesData,
});
