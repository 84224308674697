/* eslint-disable max-len */
import { Cmd, loop } from 'redux-loop';
import { stateTransitionTo } from 'redux-ui-router';
import * as ACTION_TYPES from '../constants/actionTypes';
import * as COMMANDS from '../commands/newsCommands';
import * as ACTIONS from '../actions/newsActions';
import {
  buildFilter, getNewsPath, getIssuedNewsitems, getNextNewsitem, getPreviousNewsitem,
} from '../helpers/newsHelpers';
import utils from '../../utils/utils';

const initialState = {
  items: [],
  page: 1,
  filter: {},
};

// eslint-disable-next-line import/prefer-default-export
export default (state = initialState, action, rootState) => {
  switch (action.type) {
    case ACTION_TYPES.LOAD_NEWS: {
      const filter = buildFilter(action.payload);

      return loop({
        ...state,
        filter,
      },
      Cmd.run(COMMANDS.loadNews, {
        args: [{ ...rootState.router.currentParams }],
        successActionCreator: ACTIONS.setNews,
        failActionCreator: error => console.log('ERROR loading content tree:', error),
      }));
    }

    case ACTION_TYPES.SET_NEWS: {
      return {
        ...state,
        items: utils.sortByIssuedDate(getIssuedNewsitems(action.payload)),
      };
    }

    case ACTION_TYPES.LOAD_MORE_NEWS: {
      return {
        ...state,
        page: state.page + 1,
      };
    }

    case ACTION_TYPES.UPDATE_NEWS_FILTER: {
      const filter = buildFilter(action.payload);

      return loop({
        ...state,
        filter,
      },
      Cmd.action(stateTransitionTo('newsDatabank', filter, { location: 'replace' })));
    }

    case ACTION_TYPES.CLEAR_NEWS_FILTER: {
      return loop({
        ...state,
      },
      Cmd.action(stateTransitionTo('newsDatabank', {}, { location: 'replace' })));
    }

    case ACTION_TYPES.GO_TO_NEWS_OVERVIEW: {
      return loop({
        ...state,
      },
      Cmd.action(stateTransitionTo('newsDatabank', state.filter, { location: true })));
    }

    case ACTION_TYPES.GO_TO_NEXT_NEWS: {
      const news = getNextNewsitem(action.payload, state.items);
      return loop({
        ...state,
      },
      news ? Cmd.action(stateTransitionTo('transitioning', {
        pathToResolve: getNewsPath(news, rootState.configurations.webpages),
      }, { location: true })) : Cmd.none);
    }

    case ACTION_TYPES.GO_TO_PREVIOUS_NEWS: {
      const news = getPreviousNewsitem(action.payload, state.items);
      return loop({
        ...state,
      },
      news ? Cmd.action(stateTransitionTo('transitioning', {
        pathToResolve: getNewsPath(news, rootState.configurations.webpages),
      }, { location: true })) : Cmd.none);
    }

    default:
      return state;
  }
};
