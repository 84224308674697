// This contains most code for supporting OAuth, translations, and remote logging.

module.exports = ($provide, $stateProvider, $urlRouterProvider, cfpLoadingBarProvider,
  ngMetaProvider, $locationProvider, settings, AnalyticsProvider, $ngReduxProvider) => {
  'ngInject';

  require('./vskoconfig').default($provide);
  require('./routes').default($stateProvider, $urlRouterProvider, $locationProvider, ngMetaProvider, $ngReduxProvider);

  cfpLoadingBarProvider.includeBar = false;
  cfpLoadingBarProvider.includeSpinner = false;

  if (settings.googlAnalytics.enabled) {
    AnalyticsProvider.setAccount(settings.googlAnalytics.token);
    AnalyticsProvider.delayScriptTag(true);
  }
};
