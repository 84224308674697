import * as CONTENT_ACTIONS from '../../reduxLoop/actions/contentActions';
import * as NEWS_ACTIONS from '../../reduxLoop/actions/newsActions';

require('./wsNewsDetail.scss');

class wsNewsDetail {
  constructor($scope, $element, ngMeta, $sce, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;
    this.ngMeta = ngMeta;
    this.$sce = $sce;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect(state => ({
      key: state.vm.newsPage.key,
      title: state.vm.newsPage.title,
      description: state.vm.newsPage.description,
      category: state.vm.newsPage.category,
      date: state.vm.newsPage.date,
      coverImage: state.vm.newsPage.coverImage,
      contentItems: state.vm.newsPage.firstLevelItems,
      previousItemPath: state.vm.newsPage.previousItemPath,
      nextItemPath: state.vm.newsPage.nextItemPath,
      loading: state.vm.newsPage.loading,
    }))(this);

    this.$ngRedux.dispatch(CONTENT_ACTIONS.loadContentTree(this.sSource));

    this.$scope.$emit('showCompactHeader', true);
  }

  $onDestroy() {
    this.$scope.$emit('showCompactHeader', false);
  }

  backToOverview() {
    this.$ngRedux.dispatch(NEWS_ACTIONS.goToNewsOverview());
  }
}

export default {
  template: require('./wsNewsDetail.html'),
  controllerAs: 'ctrl',
  controller: wsNewsDetail,
  bindings: {
    sSource: '<',
    sSourceParam: '<',
  },
};
