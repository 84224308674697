// codes
export const WWW_HARD_CODED = 'WWW_HARD_CODED';
export const WWW_CURRENT_THEME_PAGE = 'WWW_CURRENT_THEME_PAGE';
export const WWW_INFORMATION_PAGE = 'WWW_INFORMATION_PAGE';
export const WWW_NEWS_DETAIL = 'WWW_NEWS_DETAIL';
export const WWW_HOME_PAGE = 'WWW_HOME_PAGE';

export const DEFAULT_TEMPLATE_STATE = 'DEFAULT_TEMPLATE_STATE';
export const TRANSITIONING_STATE = 'transitioning';

export const WWW_SEARCH_TYPE = 'www_web_page';
export const NEWS_IN_HOME_TYPES = ['NEWS_ITEM', 'PRESS_RELEASE'];
export const NEWS_SEARCH_TYPES = ['NEWS_ITEM', 'JOB_OFFER', 'PRESS_RELEASE'];
export const NEWS_FILTER_TYPES = [
  { type: 'PRESS_RELEASE', searchType: 'PRESS_RELEASE', label: 'Persbericht' },
  { type: 'NEWS_ITEM', searchType: 'NEWS_ITEM', label: 'Nieuwsbericht' },
  {
    type: 'JOB_OPENING_INTERNAL', searchType: 'JOB_OFFER', creatorType: 'INTERNAL', label: 'Vacature Katholiek Onderwijs Vlaanderen', detailsLabel: 'Vacature',
  },
  {
    type: 'JOB_OPENING_MEMBER', searchType: 'JOB_OFFER', creatorType: 'MEMBER', label: 'Vacature onderwijsinstellingen', detailsLabel: 'Vacature',
  },
  {
    type: 'JOB_OPENING_EXTERNAL', searchType: 'JOB_OFFER', creatorType: 'EXTERNAL', label: 'Vacature externen', detailsLabel: 'Vacature',
  },
];

export const NEWS_TAGS = ['NEWS_ITEM', 'JOB_OFFERING', 'PRESS_RELEASE'];
export const NEWS_PER_PAGE = 4;
export const NEWS_OVERVIEW_PATH = 'nieuwsoverzicht';

export const TREE_ITEM_TYPES = ['STRUCTURED_DOCUMENT',
  'UNSTRUCTURED_DOCUMENT',
  'SECTION', 'PARAGRAPH',
  'IMAGE', 'SUMMARY', 'FAQ',
  'FAQ_GROUP', 'LEGAL', 'LINK_GROUP',
  'REFERENCE', 'QUOTE', 'ATTACHMENTS_GROUP',
  'ATTACHMENT', 'IMAGE_GROUP', 'VIDEO', 'REFERENCE_GROUP', 'EXAMPLE'];

export const LANGUAGES_BY_PATH = {
  '/frans': 'FR',
  '/engels': 'EN',
};

export const COOKIE_PAGE_PATH = '/cookies';

export const COOKIE_TYPE = {
  ANALYTIC: 'cookies__analytic',
  SOCIAL: 'cookies__social',
};

export const HEADER_MAIN_MENU_KEY = 'af9e30c0-6453-4724-ae79-8f3be359673c';
export const HEADER_MOBILE_MENU_KEY = 'a5155e64-9f0a-450d-acbb-0cd958c17f2b';
export const HEADER_OVERLAY_MENU_KEY = '76bda050-1e83-49ec-8326-bb248a1cf5f4';
export const FOOTER_MENU_KEY = 'e2732658-2bfe-4df6-874a-b6d84a552d70';
