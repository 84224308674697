import { contentApi, cachedContentApi } from '../api/apiConfig';

export const getContentHash = (href, isPreview) => contentApi.get(`${href}/hash${isPreview ? `?${new Date().toISOString()}` : ''}`, {});

export const loadRoot = async (rootHref, types = []) => {
  const hash = await getContentHash(rootHref);

  const items = await cachedContentApi.getAll('/content', {
    root: rootHref,
    typeIn: types.join(','),
  }, { headers: { 'VSKO-Resource-Hash': hash.hash } });

  return {
    href: rootHref,
    items,
  };
};
