/* eslint-disable max-len */
import { WWW_NEWS_DETAIL } from '../static/constants';
import { isPageOfType } from '../helpers/themaHelpers';
import utils from '../../utils/utils';
import {
  getNextNewsitem, getPreviousNewsitem, getNewsPath, getNewsDetailCategory,
} from '../helpers/newsHelpers';

function firstLevelItems(tree) {
  if (!tree) {
    return [];
  }
  return tree.children;
}

export default (tree, state) => {
  if (!tree || !isPageOfType(WWW_NEWS_DETAIL, state)) {
    return {
      loading: true,
    };
  }

  return {
    key: tree.key,
    title: tree.title,
    description: tree.description,
    category: getNewsDetailCategory(tree),
    date: utils.getFormattedDate(tree.issued),
    coverImage: tree.coverImage.href,
    firstLevelItems: firstLevelItems(tree),
    previousItemPath: getNewsPath(getPreviousNewsitem(tree.key, state.news.items), state.configurations.webpages),
    nextItemPath: getNewsPath(getNextNewsitem(tree.key, state.news.items), state.configurations.webpages),
    loading: !tree,
  };
};
