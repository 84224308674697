import * as ACTIONS from '../../reduxLoop/actions/contentActions';
import utils from '../../utils/utils';
import { COOKIE_PAGE_PATH } from '../../reduxLoop/static/constants';
import constants from '../../components/constants';

class wsInformation {
  constructor($scope, ngMeta, $location, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.ngMeta = ngMeta;
    this.$location = $location;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect(state => {
      const ISCOOKIESPAGE = state.vm.informationPage.isCookiesPage;
      const LOADING = state.vm.informationPage.loading;
      if(ISCOOKIESPAGE && this.$location.$$hash && !LOADING){
        this.scrollTo(constants.COOKIE_CONTENT[this.$location.$$hash]);
      }
      return {
        title: state.vm.informationPage.title,
        description: state.vm.informationPage.description,
        coverImage: state.vm.informationPage.coverImage,
        items: state.vm.informationPage.firstLevelItems,
        loading: LOADING,
        isCookiesPage: ISCOOKIESPAGE,
      };
    })(this);

    this.$ngRedux.dispatch(ACTIONS.loadContentTree(this.sSource));

    this.$scope.$emit('showCompactHeader', true);

    const currentLocation = this.$location.$$path;

    this.$scope.$on('$destroy', () => {
      if (currentLocation === COOKIE_PAGE_PATH) {
        this.$scope.$emit('leaveCookies', false);
      }
    });
  }
  scrollTo(key) {
    setTimeout(() => {
      utils.scrollTo(key);
    }, 500);
  }

  sectionTitleClass() {
    return 'kov-template-text__heading kov-template-text__heading--underlined';
  }

  $onDestroy() {
    this.unsubscribe();
  }
}

export default {
  template: require('./wsInformation.html'),
  controllerAs: 'ctrl',
  controller: wsInformation,
  bindings: {
    sSource: '<',
  },
};
