import { getNow, getNonAbolishedResources } from '@kathondvla/sri-client/date-utils';
import constants from '../components/constants';
import { sriClient, contentApi, websitesApi } from '../reduxLoop/api/apiConfig';

const service = (settings) => {
  'ngInject';
  'use strict';

  async function getContent(href) {
    return contentApi.get(
      href,
      {},
    );
  }

  async function getContentHash(href, isPreview) {
    return contentApi.get(`${href}/hash${isPreview ? `?${new Date().toISOString()}` : ''}`, {});
  }

  async function getContents(hrefs) {
    return contentApi.getAll(
      '/content',
      { hrefs },
    );
  }

  async function getDescendants(href, types = [], options = {}) {
    return contentApi.getAll(
      '/content', {
        root: href,
        typeIn: types.join(','),
      },
      options,
    );
  }

  async function getAncestors(href, types = []) {
    return contentApi.getAll(
      '/content', {
        leaf: href,
        typeIn: types.join(','),
      },
      {
        baseUrl: settings.apisAndUrls.cachedContentApi,
      },
    );
  }

  async function getThemeRoot(href, isPreview) {
    const themeRoots = await contentApi.getAll(
      '/content',
      { leaf: href, tagsOverlaps: 'WWW' },
      { headers: (isPreview ? { 'VSKO-Resource-Hash': new Date().toISOString() } : {}) },
    );

    return themeRoots.length > 0 ? themeRoots[0] : undefined;
  }

  async function getThemeDescendants(href, isPreview) {
    const themes = await contentApi.getAll(
      '/content',
      {
        root: href,
        type: 'THEME',
      },
      { headers: (isPreview ? { 'VSKO-Resource-Hash': new Date().toISOString() } : {}) },
    );
    return themes;
  }

  async function getReferenceFrame(isPreview = false) {
    return contentApi.getAll('/content', {
      root: constants.NAVIGATION.THEME_REFERENCE_FRAME.split('/').pop(),
      typeIn: 'REFERENCE_FRAME,THEME,SECTION',
    },
    { headers: (isPreview ? { 'VSKO-Resource-Hash': new Date().toISOString() } : {}) });
  }

  async function getThemes(isPreview = false) {
    return contentApi.getAll('/content', {
      tagsOverlaps: 'WEBPAGE2,PRO_PAGE',
    },
    { headers: (isPreview ? { 'VSKO-Resource-Hash': new Date().toISOString() } : {}) });
  }

  async function getProHomePages(isPreview = false) {
    return websitesApi.getAll('/webpages', {
      website: settings.website,
      type: 'WWW_HOME_PAGE',
      'source.href': settings.proHomepage,
    }, {
      basePath: settings.apisAndUrls.cachedWebsitesApi,
      headers: (isPreview ? { 'VSKO-Resource-Hash': new Date().toISOString() } : {}),
    });
  }

  async function getProHomePage(proHomepageContent, isPreview = false) {
    return contentApi.get(proHomepageContent,
      { headers: (isPreview ? { 'VSKO-Resource-Hash': new Date().toISOString() } : {}) });
  }

  async function getHomePageHash(key, isPreview = false) {
    return contentApi.get(`/content/${key}/hash${isPreview ? `?${new Date().toISOString()}` : ''}`, {});
  }

  async function getHomePageItems(key, hash) {
    return contentApi.getAll('/content', {
      root: key,
      typeIn: 'STRUCTURED_DOCUMENT,SECTION,ATTACHMENT',
    },
    { headers: { 'VSKO-Resource-Hash': hash } });
  }

  async function getUserResponsibilities(user) {
    const responsibilities = await sriClient.getAll('/responsibilities', {
      persons: user.$$meta.permalink,
      expandPosition: 'NONE',
    });

    const nonAbolishedResponsibilities = getNonAbolishedResources(responsibilities);

    return nonAbolishedResponsibilities;
  }

  async function getOrganisations(organisationHrefs) {
    if (organisationHrefs.length > 0) {
      return sriClient.getAll('/organisationalunits', {
        hrefs: organisationHrefs.join(','),
        expand: 'FULL',
      });
    }

    return [];
  }

  async function getUserSchoolEntities(responsibilities) {
    const samHrefs = responsibilities.filter(resp => resp.organisation.href.match(/^\/sam\//g)).map(resp => resp.organisation.href);
    const ous = await sriClient.getAllHrefs(samHrefs);
    return ous;
  }

  async function getUserOrganisations(responsibilities) {
    const hrefs = responsibilities.filter(
      resp => resp.organisation.href.match(/^\/(schools|cvos|boardings|governinginstitutions|schoolcommunities)\//g),
    ).map(resp => resp.organisation.href);


    return getOrganisations(hrefs);
  }

  async function getOusLocations(ous) {
    if (ous.length === 0) {
      return [];
    }

    const ouHrefs = ous.map(ou => ou.$$meta.permalink);

    return sriClient.getAll('/sam/organisationalunits/locations', {
      organisationalUnit: ouHrefs.join(','),
      expand: 'results.physicalLocation',
      endDateAfter: getNow(),
    });
  }

  async function getOrganisationsLocations(organisations) {
    if (organisations.length === 0) {
      return [];
    }

    const locationHrefs = [];

    organisations.forEach((organisation) => {
      const locations = organisation.$$locations
        ? getNonAbolishedResources(organisation.$$locations) : [];

      locations.forEach((location) => {
        if (!locationHrefs.includes(location.href)) {
          locationHrefs.push(location.href);
        }
      });
    });

    const locationPromises = locationHrefs.map(
      locationHref => sriClient.get(locationHref),
    );

    return Promise.all(locationPromises);
  }

  async function getDiocesesFromZipcodes(zipcodes) {
    if (zipcodes.length === 0) {
      return [];
    }

    const subcities = await sriClient.getAll('/subcities', {
      zipcodes: zipcodes.join(','),
    });

    let subcityHrefs = subcities.map(subcity => subcity.city.href);
    subcityHrefs = [...new Set(subcityHrefs)];

    const promises = subcityHrefs.map(subcityHref => sriClient.get(subcityHref));
    const cities = await Promise.all(promises);

    const dioceses = cities.filter(
      city => city.diocese && city.diocese.href,
    ).map(city => city.diocese.href);

    return [...new Set(dioceses)];
  }

  async function getSchoolEntitiesMainstructureHrefs(ous) {
    if (ous.length === 0) {
      return [];
    }
    const ouHrefs = ous.map(ou => ou.$$meta.permalink);

    let educationalprogrammedetails = await sriClient.getAll('/sam/educationalprogrammedetails', {
      organisationalUnit: ouHrefs.join(','),
      expand: 'results.ag',
      hasStudyProgramme: 'false',
    });

    educationalprogrammedetails = getNonAbolishedResources(educationalprogrammedetails);

    return educationalprogrammedetails.filter(
      educationalprogrammedetail => educationalprogrammedetail.mainstructure
      || (educationalprogrammedetail.ag && educationalprogrammedetail.ag.$$expanded.mainstructure),
    ).map(
      educationalprogrammedetail => (educationalprogrammedetail.mainstructure
        && educationalprogrammedetail.mainstructure.href)
        || educationalprogrammedetail.ag.$$expanded.mainstructure.href,
    );
  }

  async function savePrivateState(state) {
    return sriClient.put(state.$$meta.permalink, state);
  }

  function getUserPreferenceObject(userPreferences) {
    const userPreferenceObject = {
      regions: [],
      institutionType: [],
      educationLevel: [],
    };

    Object.keys(userPreferences).forEach((i) => {
      userPreferences[i].forEach((j) => {
        if (j.checked) {
          if (i === 'REGIONS') {
            userPreferenceObject.regions.push(j);
          } else if (j.ouType === 'SCHOOL' && j.tag !== 'ho') {
            if (j.title === 'Deeltijds kunstonderwijs') j.tag = 'dko';
            userPreferenceObject.educationLevel.push(j);
          } else {
            userPreferenceObject.institutionType.push(j);
          }
        }
      });
    });

    return userPreferenceObject;
  }

  function getRelevantLabelsFromTags(items, userPreferences) {
    const userPreferenceObject = getUserPreferenceObject(userPreferences);
    const finalTags = [];
    // DKO doesn´t exist in user preferences so get rid of it

    userPreferenceObject.educationLevel = userPreferenceObject.educationLevel.filter(e => e.tag !== 'dko');

    Object.keys(userPreferenceObject).forEach((type) => {
      const tags = userPreferenceObject[type].map(tagObject => tagObject.tag);
      if (!tags.every(t => items.includes(t))) {
        tags.forEach((t) => {
          if (items.includes(t)) {
            finalTags.push(t);
          }
        });
      }
    });
    return finalTags;
  }

  function getApplicabilityLabels(items) {
    return items.map((item) => {
      if (!item.coverage) item.coverage = [];
      if (!item.mainstructures) item.mainstructures = [];
      if (!item.outypes) item.outypes = [];

      const tags = item.coverage.map((d) => {
        const region = constants.REGIONS.find(r => r.href === d);
        return region.tag;
      }).concat(item.mainstructures.map((m) => {
        const educationLevel = constants.EDUCATION_LEVELS.find(
          level => level.mainstructures.includes(m),
        );
        return educationLevel.tag;
      })).concat(item.outypes.map((o) => {
        // eslint-disable-next-line default-case
        switch (o) {
          case 'CVO':
            return 'vwo';
          case 'BOARDING':
            return 'int';
          default:
            // SCHOOL is already covered by mainstructure check above
            return undefined;
        }
      }));
      item.tags = [...new Set(tags.filter(tag => tag))];

      return item;
    });
  }

  async function checkTrainings(themeArray) {
    const themes = themeArray.join(',');
    const searchParameters = {
      themes,
      types: 'TRAINING_MODULE',
      limit: 1,
    };

    const rawTrainingData = await sriClient.getRaw('/search',
      searchParameters,
      { baseUrl: settings.apisAndUrls.trainings.search });
    return rawTrainingData.results.length > 0;
  }

  async function checkNews(themeArray) {
    const themes = themeArray.join(',');
    const searchParameters = {
      themesContains: themes,
      type: 'TEASER',
      limit: 1,
    };

    const rawNewsData = await contentApi.getAll('/content',
      searchParameters,
      { baseUrl: settings.apisAndUrls.api });

    return rawNewsData.length > 0;
  }

  async function getThemeRootWebpageInfo(href) {
    const structuredDocuments = await contentApi.getAll(
      '/content', {
        themesContains: href,
        type: 'STRUCTURED_DOCUMENT',
      },
    );
    if (structuredDocuments.length > 0) {
      const webpageInfo = await websitesApi.getAll(
        '/webpages', {
          'source.href': structuredDocuments[0].$$meta.permalink,
        },
      );
      if (webpageInfo.length > 0) {
        webpageInfo[0].title = structuredDocuments[0].title;
        return webpageInfo[0];
      }
    }
    return null;
  }


  async function getFacetCategories(href) {
    const referenceFrameDescendants = await getDescendants(
      href,
      ['REFERENCE_FRAME', 'THEME', 'THEME_GROUP'],
      {
        baseUrl: settings.apisAndUrls.cachedContentApi,
      },
    );

    const referenceFrame = referenceFrameDescendants.find(category => category.type === 'REFERENCE_FRAME');

    const categories = [];
    referenceFrame.$$relationsTo.sort((a, b) => a.$$expanded.readorder - b.$$expanded.readorder);
    referenceFrame.$$relationsTo.forEach((relation) => {
      categories.push(referenceFrameDescendants.find(
        descendant => descendant.$$meta.permalink === relation.$$expanded.from.href,
      ));
    });

    return categories;
  }

  return {
    checkTrainings,
    checkNews,
    getContent,
    getContentHash,
    getContents,
    getDescendants,
    getAncestors,
    getThemeRoot,
    getReferenceFrame,
    getProHomePages,
    getProHomePage,
    getThemes,
    getThemeDescendants,
    getHomePageHash,
    getHomePageItems,
    getUserResponsibilities,
    getUserSchoolEntities,
    getOrganisations,
    getOusLocations,
    getUserOrganisations,
    getOrganisationsLocations,
    getDiocesesFromZipcodes,
    getSchoolEntitiesMainstructureHrefs,
    savePrivateState,
    getApplicabilityLabels,
    getRelevantLabelsFromTags,
    getFacetCategories,
    getThemeRootWebpageInfo,
  };
};

angular.module('services').factory('dataService', service);
