/* eslint-disable max-len */
import moment from 'moment';
import { getNow } from '@kathondvla/sri-client/date-utils';
import { getWebpageInfoFromHref } from './themaHelpers';
import {
  NEWS_FILTER_TYPES, NEWS_OVERVIEW_PATH, WWW_SEARCH_TYPE,
} from '../static/constants';
import contentUtils from '../../utils/contentUtils';
import utils from '../../utils/utils';

function getNewsType(searchApiItem) {
  const filterTypes = NEWS_FILTER_TYPES.filter(i => i.searchType === searchApiItem.type);
  if (searchApiItem.creators && searchApiItem.creators.length) {
    // must have only one creator
    return filterTypes.find(ft => ft.creatorType === searchApiItem.creators[0].creatorType);
  }
  return filterTypes[0];
}

export const buildFilter = (params) => {
  const from = params.van ? moment(params.van).format('YYYY-MM-DD') : '';
  const to = params.tot ? moment(params.tot).format('YYYY-MM-DD') : '';
  console.log('Build filter for state from:', params.van, params.van ? moment(params.van).format('YYYY-MM-DD') : undefined);
  return {
    q: params.q,
    types: params.types,
    van: from,
    tot: to,
  };
};

export const buildRequestParams = (filter) => {
  // use correct search-api params to filter by issued date
  // by default we show every news items issued on the past
  filter.issuedBeforeOrEqual = getNow();

  if (filter.van) {
    filter.issuedAfterOrEqual = filter.van;
    delete filter.van;
  }

  if (filter.tot) {
    filter.issuedBeforeOrEqual = filter.tot;
    delete filter.tot;
  }

  const searchTypes = filter.types
    ? filter.types.split(',').map(type => NEWS_FILTER_TYPES.find(i => i.type === type))
    : NEWS_FILTER_TYPES;

  filter['wwwWebPageJobOfferCreator.creatorType'] = searchTypes.reduce((list, st) => {
    if (st.creatorType) {
      list.push(st.creatorType);
    }
    return list;
  }, []);

  return {
    ...filter,
    types: WWW_SEARCH_TYPE,
    wwwWebPageType: searchTypes.map(st => st.searchType || st.type),
    limit: 100,
  };
};

export const getNewsPath = (item, webconfigurations) => {
  if (!item) {
    return undefined;
  }
  const contentHref = item.$$meta.contentHref || item.$$meta.permalink;
  const webconfig = getWebpageInfoFromHref(contentHref, webconfigurations);
  return webconfig ? webconfig.path : contentHref;
};

export const getNewsTag = (item) => {
  const type = getNewsType(item);
  return type ? type.label.toLowerCase() : '';
};

export const getNewsTagSearchLink = (item) => {
  const filterType = getNewsType(item);
  return filterType ? `/${NEWS_OVERVIEW_PATH}?types=${filterType.type}` : '';
};

export const getIssuedNewsitems = (items = []) => items.filter(item => item.issued);

export const getNextNewsitem = (key, items) => {
  const idx = items.findIndex(searchApiItem => searchApiItem.$$meta.contentHref === `/content/${key}`);
  return items[idx + 1];
};

export const getPreviousNewsitem = (key, items) => {
  const idx = items.findIndex(searchApiItem => searchApiItem.$$meta.contentHref === `/content/${key}`);
  return items[idx - 1];
};

export const getNewsDetailCategory = (item) => {
  const type = NEWS_FILTER_TYPES.find(t => item.tags.some(tg => tg === t.type || tg === t.searchType));
  return type ? (type.detailsLabel || type.label).toLowerCase() : '';
};

export const itemVM = (item, webconfigurations) => {
  const thumbnail = contentUtils.getImageWithSizeFromSearchResult(item, 'thumbnails', { w: 500, h: 360 });

  return {
    ...item,
    thumbnailImage: thumbnail ? thumbnail.href : undefined,
    path: getNewsPath(item, webconfigurations),
    date: utils.getFormattedDate(item.issued ? item.issued.startDate : undefined),
    tag: getNewsTag(item),
    tagSearchLink: getNewsTagSearchLink(item),
  };
};
