module.exports = {
  angularModuleName: 'pro',
  projectName: 'pro',
  projectVersion: 'local',
  environment: 'test',
  release: 'local',
  projectDescription: 'The pro website of Katholiek Onderwijs Vlaanderen.',
  googlAnalytics: {
    enabled: true,
    token: 'UA-64260235-19',
  },
  apisAndUrls: {
    api: 'https://testapi.katholiekonderwijs.vlaanderen',
    contentApi: 'https://testapi.katholiekonderwijs.vlaanderen',
    cachedContentApi: 'https://testcached-api.katholiekonderwijs.vlaanderen',
    websitesApi: 'https://testapi.katholiekonderwijs.vlaanderen',
    cachedWebsitesApi: 'https://testcached-api.katholiekonderwijs.vlaanderen',
    pro: 'https://testpro.katholiekonderwijs.vlaanderen',
  },
  logging: {
    sentry: {
      enabled: true,
      url: 'https://7acc39d3601d4471be474308cb36ca96@o268751.ingest.sentry.io/5649941',
    },
  },
  prerender: {
    token: 'XviHbBzWrXNJwKRYTvpa',
  },
  trustedOrigins: [
    '*',
  ],
  proHomepage: '/content/890a355d-4c52-412d-ad91-82474a363cac',
  website: '/websites/f8a0049e-d0c0-4bff-81f1-4403297395f3',
  allowNotPublished: true,
  component: '/security/components/pro',
  defaultColor: '#808080',
  defaultIcon: 'https://cached-api.katholiekonderwijs.vlaanderen/content/28ec313a-7869-4ae6-a8ef-2bf23adaa708/attachments/veldicoontjes-08.svg',
};
