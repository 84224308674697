/* eslint-disable prefer-destructuring */
import settings from '../config/settings';
import constants from '../components/constants';

export default {
  getImage(item, type, ifNotFound) {
    if (item.attachments) {
      const image = item.attachments.filter(o => o.type === type)[0];

      if (image) {
        return {
          href: image.href.startsWith('/content')
            ? (settings.apisAndUrls.cachedContentApi + encodeURI(image.href) + (sessionStorage.getItem('preview') ? `?hash=${item.$$meta.hash}` : '')) : image.href,
          description: image.description ? image.description : null,
          rightsHolder: image.rightsHolder ? image.rightsHolder : null,
          sizes: item.attachments.filter(o => o.type === type && o.resized),
          contentType: image.contentType,
        };
      }
    }
    return ifNotFound || null;
  },

  getImageWithSize(item, type, size, ifNotFound) {
    const image = this.getImage(item, type);

    if (image && image.sizes) {
      let sizedImage = null;
      if (size.w && size.h) {
        [sizedImage] = image.sizes.filter(o => o.width === size.w && o.height === size.h);
      } else {
        [sizedImage] = image.sizes.filter(o => o.width === size.w);
      }
      if (image.sizes && sizedImage) {
        return {
          href: sizedImage.href.startsWith('/content')
            ? (settings.apisAndUrls.cachedContentApi + encodeURI(sizedImage.href) + (sessionStorage.getItem('preview') ? `?hash=${item.$$meta.hash}` : '')) : image.href,
          description: image.description ? image.description : null,
          rightsHolder: image.rightsHolder ? image.rightsHolder : null,
        };
      }
      return image;
    }
    return ifNotFound || null;
  },

  getImageWithSizeFromSearchResult(item, field, size = {}, ifNotFound) {
    if (item[field] && item[field].length) {
      let image = item[field].find(i => +i.width === size.w && +i.height === size.h);

      if (!image) {
        image = item[field][0];
      }

      return {
        href: image.href.startsWith('/content')
          ? (settings.apisAndUrls.cachedContentApi + encodeURI(image.href) + (sessionStorage.getItem('preview') ? `?hash=${item.$$meta.hash}` : '')) : image.href,
        description: image.description ? image.description : null,
        rightsHolder: image.rightsHolder ? image.rightsHolder : null,
        contentType: image.contentType,
      };
    }
    return ifNotFound || null;
  },

  getAttachment(item, types) {
    const attachment = item.attachments.filter(o => types.includes(o.type))[0];
    if (attachment && attachment.href) {
      return {
        href: attachment.href.startsWith('http')
          ? attachment.href : settings.apisAndUrls.cachedContentApi + attachment.href,
        description: attachment.description ? attachment.description : attachment.name,
        rightsHolder: attachment.rightsHolder ? attachment.rightsHolder : null,
        contentType: attachment.contentType,
        name: attachment.name,
        size: attachment.size ? this.calculateSize(attachment.size) : '-',
      };
    }
    return null;
  },

  addRequirements(item) {
    if (item.$$relationsTo) {
      const requirements = item.$$relationsTo.filter(o => o.$$expanded.relationtype === 'REQUIRES');
      return requirements.map(o => o.$$expanded.from.href);
    }
    return [];
  },

  addReferences(item) {
    if (item.$$relationsFrom) {
      const references = item.$$relationsFrom.filter(
        o => o.$$expanded.relationtype === 'REFERENCES' && o.$$expanded.to.href.startsWith('/content'),
      );
      return references.map(o => o.$$expanded.to.href);
    }
    return [];
  },

  calcExternalLink(item) {
    if (item.type === 'REFERENCE') {
      const extRef = item.$$relationsFrom.filter(
        o => o.$$expanded.relationtype === 'REFERENCES' && !o.$$expanded.to.href.startsWith('/content'),
      )[0];
      if (extRef) {
        const { href } = extRef.$$expanded.to;
        return href.startsWith('http') ? href : `http://${href}`;
      }
    }
    return null;
  },

  calcVideoPlayback(item) {
    if (item.type === 'VIDEO') {
      const videoItem = item.attachments.filter(o => o.type === 'VIDEO')[0];
      if (videoItem) {
        const url = videoItem.playbackUrl;
        if (url !== undefined && url !== '') {
          const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
          const match = url.match(regExp);
          if (match && match[2].length === 11) {
            return `https://youtube.com/embed/${match[2]}`;
          }
        }
      }
    }

    return null;
  },
  calculateSize(sizeInBytes) {
    if (sizeInBytes === undefined) {
      return '-';
    }

    if (sizeInBytes >= 1048576) {
      const sizeInMB = Number(sizeInBytes / 1024 / 1024).toFixed(0);
      return `${sizeInMB}MB`;
    }

    const sizeInKB = Number(sizeInBytes / 1024).toFixed(0);
    return `${sizeInKB}KB`;
  },

  getAttachmentType(attachment) {
    return attachment && attachment.contentType ? constants.FILE_TYPES[attachment.contentType] : 'Andere';
  },

  isHomePath(path) {
    return path === '/';
  },

  decodeHTML(html) {
    if (!html) {
      return undefined;
    }
    const txt = document.createElement('textarea');
    txt.innerHTML = html.replace(/<br>/g, '');
    return txt.value;
  },
};
