class wsNoAccess {
  constructor() {
    'ngInject';
  }

  $onInit() {

  }
}

module.exports = {
  template: require('./wsNoAccess.html'),
  controllerAs: 'ctrl',
  controller: wsNoAccess,
  bindings: {},
};
