const contentUtils = require('../../../utils/contentUtils');

class wsContentBlockAttachments {
  constructor() {
    'ngInject';
  }

  $onInit() {
  }

  getAttachmentType(attachment) {
    return ''; // contentUtils.getAttachmentType(attachment);
  }
}

module.exports = {
  template: require('./wsContentBlockAttachments.html'),
  controllerAs: 'ctrl',
  controller: wsContentBlockAttachments,
  bindings: {
    sItem: '<',
    sColor: '<',
  },
};
