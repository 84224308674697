import * as ACTIONS from '../../reduxLoop/actions/contentActions';
import utils from '../../utils/utils';
import settings from '../../config/settings';

class wsThemeDetail {
  constructor($scope, $element, ngMeta, $sce, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;
    this.ngMeta = ngMeta;
    this.$sce = $sce;
    this.$ngRedux = $ngRedux;

    this.defaultProUrl = settings.apisAndUrls.pro;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect(state => ({
      themaTitle: state.vm.themePage.title,
      themaCoverImage: state.vm.themePage.coverImage,
      tocSections: state.vm.themePage.tocSections,
      recapSection: state.vm.themePage.recapSection,
      visionSection: state.vm.themePage.visionSection,
      explanationSection: state.vm.themePage.explanationSection,
      proUrl: state.vm.themePage.proUrl || this.defaultProUrl,
      loading: state.vm.themePage.loading,
    }))(this);

    this.$ngRedux.dispatch(ACTIONS.loadContentTree(this.sSource));

    this.$scope.$emit('showCompactHeader', true);
  }

  $onDestroy() {
    this.$scope.$emit('showCompactHeader', false);
  }

  scrollTo(key) {
    utils.scrollTo(key);
  }
}

export default {
  template: require('./wsThemeDetail.html'),
  controllerAs: 'ctrl',
  controller: wsThemeDetail,
  bindings: {
    sSource: '<',
    sSourceParam: '<',
  },
};
