module.exports = {
  angularModuleName: 'pro',
  projectName: 'pro',
  projectVersion: 'sprint-239-0-r',
  release: 'sprint-239-0-r',
  environment: 'acc',
  projectDescription: 'The public website of Katholiek Onderwijs Vlaanderen.',
  googlAnalytics: {
    enabled: 'false',
    token: 'UA-64260235-19',
  },
  apisAndUrls: {
    api: 'https://accapi.katholiekonderwijs.vlaanderen',
    contentApi: 'https://vsko-dcontent-api-acc.herokuapp.com',
    cachedContentApi: 'https://vsko-dcontent-api-acc.herokuapp.com',
    websitesApi: 'https://vsko-dwebsites-api-acc.herokuapp.com',
    cachedWebsitesApi: 'https://testcached-api.katholiekonderwijs.vlaanderen',
    pro: 'https://accpro.katholiekonderwijs.vlaanderen',
  },
  logging: {
    sentry: {
      enabled: 'false',
      url: 'https://7acc39d3601d4471be474308cb36ca96@o268751.ingest.sentry.io/5649941',
    },
  },
  trustedOrigins: [
    '*',
  ],
  prerender: {
    token: 'XviHbBzWrXNJwKRYTvpa',
  },
  proHomepage: '/content/c63847ea-be2e-44ec-abf1-ead0e4bac37f',
  website: '/websites/70b12dcc-724b-46b8-9ab9-b8ecdf35a0ad',
  defaultColor: '#F6BD16',
  defaultIcon: 'https://cached-api.katholiekonderwijs.vlaanderen/content/28ec313a-7869-4ae6-a8ef-2bf23adaa708/attachments/veldicoontjes-08.svg',
};
