/* eslint-disable max-len */
import { WWW_INFORMATION_PAGE } from '../static/constants';
import {
  getCurrentPageWebConfigurationType,
  isCookiesPage,
} from '../helpers/themaHelpers';

function firstLevelItems(tree) {
  if (!tree) {
    return [];
  }
  return tree.children;// .filter(child => child.type === 'SECTION');
}

function isInformation(state) {
  return getCurrentPageWebConfigurationType(state) === WWW_INFORMATION_PAGE;
}

export default (tree, state) => {
  if (!tree || !isInformation(state)) {
    return {
      loading: true,
    };
  }

  return {
    title: tree.title,
    description: tree.description,
    coverImage: tree.coverImage.href,
    firstLevelItems: firstLevelItems(tree),
    isCookiesPage: isCookiesPage(state),
    loading: !tree,
  };
};
