import * as ACTIONS from '../../reduxLoop/actions/contentActions';
import contentUtils from '../../utils/contentUtils';

class wsStaticPage {
  constructor($scope, $element, ngMeta, $sce, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;
    this.ngMeta = ngMeta;
    this.$sce = $sce;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect(state => ({
      body: this.$sce.trustAsHtml(contentUtils.decodeHTML(state.vm.staticPage.html)),
      coverImage: state.vm.staticPage.coverImage,
      loading: state.vm.staticPage.loading,
    }))(this);

    // this.$ngRedux.dispatch(ACTIONS.initStaticPage(this.sSource));
    this.$ngRedux.dispatch(ACTIONS.loadContentTree(this.sSource));

    this.$scope.$emit('showCompactHeader', true);
  }

  $onDestroy() {
    this.$scope.$emit('showCompactHeader', false);
  }
}

export default {
  template: require('./wsStaticPage.html'),
  controllerAs: 'ctrl',
  controller: wsStaticPage,
  bindings: {
    sSource: '<',
    sSourceParam: '<',
  },
};
